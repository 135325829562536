@mixin theme-colors($text, $heading, $background, $foreground, $active) {
  & {
    color: $text;
    background-color: $background;

    &::before {
      content: '';
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      background-color: $background;
      z-index: -2;
    }

    .backgroundColor {
      background-color: $background;
    }
    @for $i from 1 through 6 {
      h#{$i} {
        color: $heading;
      }
    }

    div,
    li,
    p,
    span {
      color: $text;
    }

    header {
      background-color: $background;
    }

    button {
      background-color: $foreground;
      color: $text;
      box-sizing: border-box;
      position: relative;

      &.active,
      &:focus,
      &:hover {
        background-color: $active;
        color: $text;
      }
    }
    // :focus,:focus::before,:focus::after{
    //   border: solid 1px red;
    // }
    article:focus {
      outline: none;
    }

    a {
      &:link {
        color: $heading;
        // opacity: 0.9;
        // text-decoration: underline #970d0d wavy 1px !important;
        text-decoration: none;
      }
      /* visited link */
      &:visited {
        color: $heading;
        // opacity: 0.8;
      }

      &:hover {
        opacity: 1;
        z-index: 2;
        position: relative;
        color: $text;
        transition: none;
        &::before{
          content: '';
          position: absolute;
          width: calc(100% + 0.6em);
          height: calc(100% + 0.5em);
          left: 50%;
          top:50%;
          transform: translate(-50%,-50%);
          z-index: -1;
          background-color: $active;
          color:  $text;
        }
      }
    }

    .focusable,
    a,
    button,
    input {
      &:focus {
        content: '';
        // width: 90%;
        // height: 90%;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
        outline: 1px solid $text;
        // outline-offset: 0.1em;
        // z-index: 3;
        // pointer-events: none;
        z-index: 2;
      }
    }

    .overlay::before {
      background-color: $background;
      opacity: 0.5;
    }

    ::selection {
      background-color: $heading;
      opacity: 1;
      color: $background;
    }

    ::-moz-selection {
      background-color: $text;
      color: $background;
    }
  }
}
@mixin theme($name, $hue) {
  .theme-#{$name}-light {
    @include theme-colors($text: hsl($hue, 60%, 7%), $heading: hsl($hue, 80%, 20%), $background: hsl($hue, 90%, 100%), $foreground: hsl($hue, 80%, 70%), $active: hsl(($hue + 12) %360, 80%, 65%));
  }

  .theme-#{$name}-dark {
    @include theme-colors($text: hsl($hue, 60%, 93%), $heading: hsl($hue, 80%, 65%), $background: hsl(($hue + 240) %360, 70%, 2%), $foreground: hsl($hue, 80%, 20%), $active: hsl(($hue + 12) %360, 80%, 35%));
  }
}
@for $i from 0 through 11 {
  @include theme($name: color#{$i}, $hue: $i * 30);
}
